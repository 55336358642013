<template>
  <div>
    <div v-if="toBePay && toBePay.total" class="notice-message" @click="toBepayOrder">
      {{ $t("点击支付 >>", { total: toBePay ? toBePay.total : 0 }) }}
    </div>

    <div class="header">
      <div class="title">
        {{ $t(convertFExpressCompany(localDelivery.fExpressCompany))
        }} {{ localDelivery.fExpressCompany ? $t("快递单号") + ":" + localDelivery.fTrackingNo : '' }}
      </div>
      <div v-if="localDelivery.fExpressCompany" class="secondary-text" style="display:flex">
        <div style="flex:1">
          {{ $t(convertLocalDeliveryStatus(localDelivery.status)) }}
          {{ formatLocalDeliveryStatusTime(localDelivery) }}
        </div>
        <span @click="copyFTrackingNo" class="cargo-tracking__header-copy">{{
          $t("[复制单号]")
        }}</span>
      </div>
    </div>

    <van-cell-group :title="$t('共count件包裹', {
          count: localDelivery.cargoList ? localDelivery.cargoList.length : 0,
        })
        ">
      <div v-for="cargo in localDelivery.cargoList" :key="cargo.id">
        <van-cell :title="cargo.trackingNo" :value="cargo.remark ? cargo.remark : ''"
          :to="{ name: 'cargoDetail', query: { id: cargo.id } }" is-link></van-cell>
      </div>
    </van-cell-group>

    <van-cell-group :title="$t('收件信息')">
      <van-cell :title="$t('收件人')" :value="localDelivery.receiverName + '/' + localDelivery.receiverMobile"></van-cell>
      <van-cell title-width="70px" :title="$t('地址')" :value="localDelivery.receiverAddress + ' ' + localDelivery.receiverPostalCode
        "></van-cell>
    </van-cell-group>

    <div class="panel">
      <van-steps desc-class="vant-step-desc" direction="vertical">
        <van-step v-for="step in steps" :key="step.id">
          <span style="font-size: 1rem">{{ step.text }}</span>
          <br />
          <span style="display: inline-block">{{ step.desc }}</span>
        </van-step>
      </van-steps>
    </div>

    <div v-if="localDelivery.custReceivedImage" class="image-wrapper">
      <van-image :src="localDelivery.custReceivedImage" fit="cover" />
    </div>
  </div>
</template>

<script>
import {
  convertFExpressCompany,
  convertLocalDeliveryStatus,
} from "@/utils/dict";
import { formatTime, formatLocalDeliveryStatusTime } from "@/utils/utils";
import { getLocalDeliveryDetail, toBePay as cargoToBePay, } from "@/service/api";
import { Image as VanImage, Cell, CellGroup, Step, Steps, Toast } from "vant";
import { EventBus } from "@/utils/event-bus.js";

export default {
  components: {
    [VanImage.name]: VanImage,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Step.name]: Step,
    [Steps.name]: Steps,
  },
  data() {
    return {
      id: this.$route.query.id,
      localDelivery: {},
      toBePay: {},
      steps: [],
    };
  },
  mounted() {
    this.getLocalDeliveryDetail();
    this.cargoToBePay()
  },
  methods: {
    copyFTrackingNo() {
      let oInput = document.createElement("input");
      oInput.value = this.localDelivery.fTrackingNo;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      Toast.success(this.$t("复制成功"));
      oInput.remove();
    },
    //跳转到付款页面
    toBepayOrder() {
      if (!this.toBePay)
        return
      const { v3, totalBySea, totalByLand } = this.toBePay
      // if (totalByLand && totalBySea) {
      //   this.$router.push({
      //     name: "paychoice",
      //     query: {
      //       v3, totalBySea, totalByLand
      //     },
      //   });
      // } else
      // const expressWay = totalByLand ? 1 : (totalBySea ? 2 : 1)
      EventBus.$emit("order-reset", {});
      this.$router.push({
        name: "order",
        query: {
          v3,
          // expressWay
        },
      });
    },
    async cargoToBePay() {
      const res = await cargoToBePay();
      if (res && res.total) {
        this.toBePay = res;
      } else {
        this.toBePay = { total: 0 };
      }
    },
    formatLocalDeliveryStatusTime(value) {
      return formatLocalDeliveryStatusTime(value);
    },
    convertFExpressCompany(value) {
      return convertFExpressCompany(value);
    },
    convertLocalDeliveryStatus(value) {
      return convertLocalDeliveryStatus(value);
    },
    async getLocalDeliveryDetail() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await getLocalDeliveryDetail(this.id);

      Toast.clear();
      const { success, data } = res
      if (success && data) {
        // const newRoutes = res.data.routes.filter((item) => item.kd100Flag == 3);
        // const steps = data.routes.map((item) => ({
        //   text: item.localTitle || item.enTitle || item.title || item.localDescription || item.enDescription || item.description,
        //   desc: formatTime(item.createdAt),
        // }));

        let steps = [];
        const locale = localStorage.getItem("locale") || "th-TH";
        switch (locale) {
          case "zh-TW":
            steps = data.routes.map((item) => ({
              text: item.title ? item.title : item.description,
              desc: formatTime(item.createdAt),
            }));
            break;
          case "th-TH":
            steps = data.routes.map((item) => ({
              text: item.localTitle ? item.localTitle : item.localDescription,
              desc: formatTime(item.createdAt),
            }));
            break;
          default:
            steps = data.routes.map((item) => ({
              text: item.enTitle ? item.enTitle : item.enDescription,
              desc: formatTime(item.createdAt),
            }));
            break;
        }

        this.localDelivery = data;
        this.steps = steps;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  background-color: #fff;
  line-height: 35px;
  padding: 15px 0 0 15px;
}

.header .title {
  font-size: 1.3rem;
}

.image-wrapper {
  padding: 10px 0px;
  text-align: center;
}

.van-cell__value {
  min-width: 70%;
}

.cargo-tracking__header-copy {
  font-size: 14px;
  margin-right: 15px;
  color: #569cd6;
}
</style>